import React, { useState } from "react";
import { Container, TextField, Button, Typography, Box } from "@mui/material";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";
import "./App.css"
import success from "./success.png"



const FormComponent = () => {
    const [captchaValue, setCaptchaValue] = useState(null);
    const [link, setLink] = useState(null)
    const [isCaptchaEntered, setIsCaptchaEnterdown] = useState(false)
    const [isFormSubmit, setIsFormSubmit] = useState(false)

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();




    const onSubmit = (data) => {
        setCaptchaValue(captchaValue);
        if (!captchaValue) {
            setIsCaptchaEnterdown(true);
            return;
        }

        const emailParams = {
            ...data,
            "g-recaptcha-response": captchaValue,
        };

        emailjs
            .send("service_wma0ea8", "template_xjjr5zt", emailParams, "XfzbbKRqXsDihyGOX")
            .then(
                () => {
                    reset();
                    setCaptchaValue(null);

                    // STEP 1: Submit to Salesforce via hidden iframe
                    const iframe = document.createElement("iframe");
                    iframe.name = "hidden_iframe";
                    iframe.style.display = "none";
                    document.body.appendChild(iframe);

                    const sfForm = document.createElement("form");
                    sfForm.method = "POST";
                    sfForm.action = "https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8";
                    sfForm.target = "hidden_iframe"; // 👈 key to avoid redirect

                    const sfFields = {
                        oid: "00DdN00000JAQRJ",
                        retURL: "", // empty to avoid redirect
                        first_name: data.firstName,
                        last_name: data.lastName,
                        email: data.email,
                        company: data.company,
                        phone: data.phone,
                    };

                    Object.entries(sfFields).forEach(([key, value]) => {
                        const input = document.createElement("input");
                        input.type = "hidden";
                        input.name = key;
                        input.value = value;
                        sfForm.appendChild(input);
                    });

                    document.body.appendChild(sfForm);
                    sfForm.submit();

                    // Clean up after submit
                    setTimeout(() => {
                        document.body.removeChild(sfForm);
                        document.body.removeChild(iframe);
                    }, 3000);

                    setIsFormSubmit(true);

                    // STEP 2: Open and download PDF
                    const originalUrl = window.location.href;
                    let updatedUrl = originalUrl.replace("https://www.xfilespro.com/", "https://www.xfilespro.com/PDF/");
                    if (updatedUrl.endsWith("/")) updatedUrl = updatedUrl.slice(0, -1);
                    const finalUrl = updatedUrl + ".pdf";
                    console.log("Final URL:", finalUrl);

                    window.open(finalUrl, '_blank');

                    const link = document.createElement("a");
                    link.href = finalUrl;
                    link.download = "XfilePro-document.pdf";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                },
                () => {
                    reset();
                    alert("Failed to send the message, please try again.");
                }
            );
    };



    console.log(captchaValue, "captcha value")

    return (
        <Container maxWidth="xs" sx={{ mt: 5 }}>
            {isFormSubmit ? <Box sx={{
                background: "#F4F4F4",
                padding: 2,
                borderRadius: 2,
                boxShadow: 3,
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
            }}>
                <Typography variant="h4" sx={{ fontWeight: "500", color: "green" }}>
                    Thank you!
                </Typography>
                <img src={success} />
                <Typography variant="p" sx={{ letterSpacing: "0.5px" }}>
                    Your request has been submitted. A team member will be in touch with business-specific
                    solutions tailored for you.
                </Typography>
            </Box> : <Box
                sx={{
                    background: "#F4F4F4",
                    padding: 2,
                    borderRadius: 2,
                    boxShadow: 3,
                    textAlign: "center",
                }}
            >
                <Typography variant="h5" sx={{ fontWeight: "bold", }}>
                    Download now to <br />know more
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                        // label="First Name *"
                        placeholder="First Name *"
                        margin="normal"
                        {...register("firstName", { required: "First name is required" })}
                        error={!!errors.firstName}
                        helperText={errors.firstName?.message}
                        sx={{

                            "& .MuiInputBase-input": {
                                padding: "10px",
                                width: "320px",
                                fontSize: ".8rem",
                                background: "#ffff"
                            },
                        }}
                    />
                    <TextField
                        // label="Last Name *"
                        placeholder="Last Name *"
                        margin="normal"
                        {...register("lastName", { required: "Last name is required" })}
                        error={!!errors.lastName}
                        helperText={errors.lastName?.message}
                        sx={{

                            "& .MuiInputBase-input": {
                                padding: "10px",
                                width: "320px",
                                fontSize: ".8rem",
                                background: "#ffff"
                                // Ensures text inside is properly styled
                            },
                        }}
                    />
                    <TextField
                        // label="Business Email *"
                        placeholder="Business Email *"

                        margin="normal"
                        type="email"
                        {...register("email", {
                            required: "Email is required",
                            pattern: {
                                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                message: "Invalid email format",
                            },
                            validate: (value) =>
                                !/(gmail\.com|yahoo\.com|hotmail\.com|outlook\.com|aol\.com)$/i.test(value) ||
                                " Please enter a business email.",
                        })}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                        sx={{

                            "& .MuiInputBase-input": {
                                padding: "10px",
                                width: "320px",
                                fontSize: ".8rem",
                                background: "#ffff"
                                // Ensures text inside is properly styled
                            },
                        }}
                    />
                    <TextField
                        placeholder="Phone"
                        margin="normal"
                        {...register("phone")}
                        sx={{

                            "& .MuiInputBase-input": {
                                padding: "10px",
                                width: "320px",
                                fontSize: ".8rem",
                                background: "#ffff"
                                // Ensures text inside is properly styled
                            },
                        }}
                    />
                    <TextField
                        // label="Company"
                        placeholder="Company"
                        margin="normal"
                        {...register("company")}
                        sx={{

                            "& .MuiInputBase-input": {
                                padding: "10px",
                                width: "320px",
                                fontSize: ".8rem",
                                background: "#ffff"
                                // Ensures text inside is properly styled
                            },
                        }}
                    />
                    <div style={{
                        transform: "scale(1.1)",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "10px",
                    }}>

                        <ReCAPTCHA sitekey="6LfTNQcrAAAAANj35p-3QtYBhRUbJgSp6xsvNx4L" onChange={setCaptchaValue} />
                        {isCaptchaEntered && <p style={{ color: "#d32f2f", fontWeight: 400, fontSize: "0.75rem", letterSpacing: "0.03333em;", marginTop: "3px", textAlign: "left", marginLeft: "0px", marginRight: "18px" }}>Please complete the reCAPTCHA verification.</p>}
                    </div>


                    <Button type="submit" variant="contained" fullWidth sx={{ mt: 2, background: "linear-gradient(90deg, #A20303 0%, #DE1B24 100%)", width: "344px" }}>
                        DOWNLOAD
                    </Button>
                </form>
                <Typography variant="body2" sx={{
                    mt: 2, fontSize: "0.8rem", color: "#666", width: "320px", marginLeft: "auto", marginRight: "auto",
                }}>
                    By filling out the form, you consent to XfilePro’s use of personal data
                    according to our <span style={{ color: "#F7374F", fontWeight: "bold" }}>Privacy Policy</span>.
                </Typography>
            </Box>}


        </Container >
    );
};

export default FormComponent;
